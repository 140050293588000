angular.module('ics')
    .controller('FSPController', function ($scope, $state, $controller, $timeout,
                                           FSPService, FirebaseStorageService,
                                           FirebaseAuthService) {

        $scope.item = {
            fspId: '',
            fsp_number: localStorage.getItem('fsp_number') || '',
            financial_year_end: localStorage.getItem('financial_year_end') || '',
            cipc_registration_number: localStorage.getItem('cipc_registration_number') || '',
            name: localStorage.getItem('name') || '',
            logo: localStorage.getItem('logo') || '',
            website: localStorage.getItem('website') || '',
            date_authorized: new Date(),
            ai_number: localStorage.getItem('ai_number') || '',
            physical_address: localStorage.getItem('physical_address') || '',
            postal_address: localStorage.getItem('postal_address') || '',
            contact_person: localStorage.getItem('contact_person') || '',
            tel: localStorage.getItem('tel') || '',
            email: localStorage.getItem('email') || '',
            compliance_officer: localStorage.getItem('compliance_officer') || '',
            separate_account: false,
            separate_account_bank_name: localStorage.getItem('separate_account_bank_name') || '',
            bank_account_details: localStorage.getItem('bank_account_details') || ''
        };

        $scope.errorMessage = '';
        $scope.tokenId = '';
        $scope.processing = '';
        $scope.title = 'Add Financial Services Provider';

        angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

        FirebaseAuthService.getUserToken()
            .then(tokenId => {
                $scope.tokenId = tokenId;
                $scope.init();
            })
            .catch(err => $scope.checkErrStatus(err));

        function uploadInit() {
            setTimeout(() => {
                let docUploadControl = document.getElementById("imageUpload");

                docUploadControl.addEventListener('change', function (e) {
                    let file = e.target.files[0];
                    if (!file || !file.name) return;
                    $('.file-field #uploading-image').removeClass('ng-hide');
                    $('.file-field .btn').addClass('ng-hide');
                    FirebaseStorageService.uploadFileToCloudStorage("/fsp_logos/", file, null).then(
                        (storageInfo) => {
                            $('.file-field #uploading-image').addClass('ng-hide');
                            $('.file-field .btn').removeClass('ng-hide');
                            $scope.item.logo = storageInfo.downloadUrl;
                            $scope.$apply();

                        }, (errMessage) => {
                            $('.file-field #uploading-image').addClass('ng-hide');
                            $('.file-field .btn').removeClass('ng-hide');
                            alert(errMessage);
                        });
                });

                // let $dateInput = $('#date_authorized').pickadate({
                //     selectMonths: true, // Creates a dropdown to control month
                //     selectYears: 100, // Creates a dropdown of 100 years to control year
                //     format: "yyyy-mm-dd"
                // });
                //
                // let datePicker = $dateInput.pickadate('picker');
                // datePicker.set('select', $scope.item.date_authorized);
            });
        }

        $scope.init = function () {

            $scope.processing = true;

            FSPService.GetFSPAllList($scope.tokenId, $scope.visitorId).then((response) => {
                $scope.fspActiveList = response.data.fsps.filter(fsp => fsp.active);
                $scope.fspArchivedList = response.data.fsps.filter(fsp => !fsp.active);

                $scope.fspActiveList.sort((a, b) => {
                    const textA = a.name.toUpperCase();
                    const textB = b.name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                $scope.fspArchivedList.sort((a, b) => {
                    const textA = a.name.toUpperCase();
                    const textB = b.name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                reset();

            }, (reject) => {
                $scope.checkErrStatus(reject);
                $scope.errorMessage = reject.message;
            });
        };

        $scope.saveToLocalStorage = function (fieldName, modelName) {
            if (Number.isNaN(parseInt($scope.fspId))) {
                localStorage.setItem(modelName, $(`#${fieldName}`).val());
            }
        };

        $scope.viewFSPDetails = function (fspId) {
            $state.transitionTo('admin-fsp-details', {fsp_id: fspId});
        };

        $scope.submitForm = function ($form) {
            if ($form.$valid) {
                $scope.saveFSP($scope.item.name,
                    $scope.item.logo,
                    $scope.item.website,
                    $scope.item.fsp_number,
                    $scope.item.cipc_registration_number,
                    $scope.item.financial_year_end,
                    $scope.item.date_authorized,
                    $scope.item.ai_number,
                    $scope.item.physical_address,
                    $scope.item.postal_address,
                    $scope.item.contact_person,
                    $scope.item.tel,
                    $scope.item.email,
                    $scope.item.compliance_officer,
                    $scope.item.separate_account,
                    $scope.item.separate_account_bank_name,
                    $scope.item.bank_account_details,
                    $scope.item.active);
            }
        };

        $scope.saveFSP = function (name, logo, website, fsp_number, cipc_registration_number, financial_year_end,
                                   date_authorized, ai_number, physical_address, postal_address, contact_person,
                                   tel, email, compliance_officer, separate_account, separate_account_bank_name, bank_account_details, active) {

            $scope.item.date_authorized = $('#date_authorized').val();

            $scope.errorMessage = '';
            $scope.processing = true;

            FirebaseAuthService.getUserToken().then(token => {

                $scope.token = token;

                if ($scope.item.fspId === '') {
                    FSPService
                        .createFSP(name, logo, website, fsp_number, cipc_registration_number, financial_year_end,
                            $scope.item.date_authorized, ai_number, physical_address, postal_address, contact_person,
                            tel, email, separate_account, separate_account_bank_name, compliance_officer, bank_account_details, active, $scope.tokenId, $scope.visitorId)
                        .then(() => {
                            $('body').addClass('bg-grey-1');
                            emptyLocalStorage();
                            location.reload();
                        })
                        .catch(err => $scope.checkErrStatus(err));
                } else {
                    FSPService
                        .editFSP(
                            $scope.item.fspId,
                            name,
                            logo,
                            website,
                            fsp_number,
                            cipc_registration_number,
                            financial_year_end,
                            $scope.item.date_authorized,
                            ai_number,
                            physical_address,
                            postal_address,
                            contact_person,
                            tel,
                            email,
                            separate_account,
                            separate_account_bank_name,
                            compliance_officer,
                            bank_account_details,
                            active,
                            $scope.tokenId, $scope.visitorId)
                        .then(() => {
                            $('body').addClass('bg-grey-1');
                            reset();
                            $scope.init();
                        })
                        .catch(err => $scope.checkErrStatus(err));
                }

            });

        };

        $scope.deleteFSP = function (fspId, index) {

            $('#modalConfirmHeader').html('Deleting Financial Service Provider');
            $('#modalConfirmText').html('Are you sure you want to delete this item?');

            let deleteType = function () {

                $scope.processing = true;

                FSPService
                    .deleteFSP(this.fspId, $scope.tokenId, $scope.visitorId)
                    .then(() => {
                        emptyLocalStorage();
                        location.reload();
                    })
                    .catch(err => $scope.checkErrStatus(err));

            };

            let deletionEvent = deleteType.bind({fspId: fspId});

            $(document).ready(function () {

                $('#modalAgreeLink').click(function () {
                    deletionEvent();
                    $('#are_you_sure_modal').modal('close');
                });

                $('#are_you_sure_modal').modal('open');

            });

        };

        $scope.editFSP = function (fspId, active, index) {
            $('body').removeClass('bg-grey-1');
            $scope.item.fspId = fspId;
            emptyLocalStorage();

            const selectedFSP = (active ? $scope.fspActiveList : $scope.fspArchivedList).find(item => item.id == fspId);

            $scope.item.fsp_number = selectedFSP.fsp_number;
            $scope.item.cipc_registration_number = selectedFSP.cipc_registration_number;
            $scope.item.financial_year_end = selectedFSP.financial_year_end;
            $scope.item.name = selectedFSP.name;
            $scope.item.logo = selectedFSP.logo;
            $scope.item.website = selectedFSP.website;
            $scope.item.date_authorized = new Date(selectedFSP.date_authorized);
            $scope.item.ai_number = selectedFSP.ai_number;
            $scope.item.physical_address = selectedFSP.physical_address;
            $scope.item.postal_address = selectedFSP.postal_address;
            $scope.item.contact_person = selectedFSP.contact_person;
            $scope.item.tel = selectedFSP.tel;
            $scope.item.email = selectedFSP.email;
            $scope.item.compliance_officer = selectedFSP.compliance_officer || '';
            $scope.item.separate_account = selectedFSP.separate_account;
            $scope.item.separate_account_bank_name = selectedFSP.separate_account_bank_name || '';
            $scope.item.bank_account_details = selectedFSP.bank_account_details || '';
            $scope.title = 'Edit Financial Services Provider';
            $scope.item.active = selectedFSP.active;

            $timeout(function () {
                $('select').material_select();
                $('textarea').trigger('autoresize');
            });

            uploadInit();

            $scope.editing = true;
        };

        $scope.addFSP = function () {
            $('body').removeClass('bg-grey-1');
            $scope.item.fsp_number = localStorage.getItem('fsp_number') || '';
            $scope.item.financial_year_end = localStorage.getItem('financial_year_end') || '';
            $scope.item.cipc_registration_number = localStorage.getItem('cipc_registration_number') || '';
            $scope.item.name = localStorage.getItem('name') || '';
            $scope.item.logo = localStorage.getItem('logo') || '';
            $scope.item.website = localStorage.getItem('website') || '';
            $scope.item.date_authorized = new Date();
            $scope.item.ai_number = localStorage.getItem('ai_number') || '';
            $scope.item.physical_address = localStorage.getItem('physical_address') || '';
            $scope.item.postal_address = localStorage.getItem('postal_address') || '';
            $scope.item.contact_person = localStorage.getItem('contact_person') || '';
            $scope.item.tel = localStorage.getItem('tel') || '';
            $scope.item.email = localStorage.getItem('email') || '';
            $scope.item.compliance_officer = localStorage.getItem('compliance_officer') || '';
            $scope.item.fspId = '';
            $scope.item.separate_account = false;
            $scope.item.separate_account_bank_name = localStorage.getItem('separate_account_bank_name') || '',
            $scope.item.bank_account_details = localStorage.getItem('bank_account_details') || '';
            $scope.item.active = true;

            $scope.editing = true;
            $scope.errorMessage = '';
            $scope.title = 'Add Financial Services Provider';

            $timeout(function () {
                $('select').material_select();
                $('textarea').trigger('autoresize');
            });

            uploadInit();
        };

        $scope.cancelSaveFSP = function () {
            $('body').addClass('bg-grey-1');
            reset();
        };

        function reset(emptyLocalStorage) {
            $scope.processing = false;

            $scope.item.fsp_number = '';
            $scope.item.financial_year_end = '';
            $scope.item.cipc_registration_number = '';
            $scope.item.fspId = '';
            $scope.item.name = '';
            $scope.item.logo = '';
            $scope.item.website = '';
            $scope.item.date_authorized = new Date();
            $scope.item.ai_number = '';
            $scope.item.physical_address = '';
            $scope.item.physical_address = '';
            $scope.item.contact_person = '';
            $scope.item.tel = '';
            $scope.item.email = '';
            $scope.item.compliance_officer = '';
            $scope.item.separate_account = false;
            $scope.item.separate_account_bank_name = '';
            $scope.item.bank_account_details = '';
            $scope.item.active = true;

            $scope.errorMessage = '';
            $scope.editing = false;
            $scope.errorMessage = '';
            $scope.title = 'Add Financial Services Provider';

            if (emptyLocalStorage) {
                emptyLocalStorage();
            }
        }

        function emptyLocalStorage() {
            localStorage.removeItem('fsp_number');
            localStorage.removeItem('financial_year_end');
            localStorage.removeItem('cipc_registration_number');
            localStorage.removeItem('name');
            localStorage.removeItem('logo');
            localStorage.removeItem('website');
            localStorage.removeItem('ai_number');
            localStorage.removeItem('physical_address');
            localStorage.removeItem('postal_address');
            localStorage.removeItem('contact_person');
            localStorage.removeItem('tel');
            localStorage.removeItem('email');
            localStorage.removeItem('compliance_officer');
            localStorage.removeItem('bank_account_details');
            localStorage.removeItem('separate_account_bank_name');
        }

    });
