angular.module('ics')

    .factory('FSPService', function ($http) {

        return {

            GetFSPList: function (firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: '/api/fsp/list',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 20000
                };

                return $http(req);

            },
            GetFSPAllList: function (firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: '/api/fsp/list_all',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 20000
                };

                return $http(req);

            },

            GetFSPDetails: function (fsp_id, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/fsp/${fsp_id}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 20000
                };

                return $http(req);

            },

            GetFSPChangeList: function (fsp_id, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/fsp/status_changes_list/${fsp_id}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 20000
                };

                return $http(req);

            },


            GetFSPFollowUpList: function (fsp_id, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/fsp/follow_up_list/${fsp_id}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 20000
                };

                return $http(req);

            },

            GetFSPChangeDetails: function (fsp_change_id, firebaseTokenId, visitorId) {

                var req = {
                    method: 'GET',
                    url: `/api/fsp/status_change/${fsp_change_id}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 20000
                };

                return $http(req);

            },

            createFSP: function (name, logo, website, fsp_number, cipc_registration_number, financial_year_end,
                                 date_authorized, ai_number, physical_address,
                                 postal_address, contact_person,
                                 tel, email, separate_account, separate_account_bank_name, compliance_officer, bank_account_details, active,
                                 firebaseTokenId, visitorId) {
                var req = {
                    method: 'POST',
                    url: '/api/fsp/',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 20000,
                    data: {
                        cipc_registration_number: cipc_registration_number,
                        financial_year_end: financial_year_end,
                        name: name,
                        logo: logo,
                        website: website,
                        fsp_number: fsp_number,
                        date_authorized: date_authorized,
                        ai_number: ai_number,
                        physical_address: physical_address,
                        postal_address: postal_address,
                        contact_person: contact_person,
                        tel: tel,
                        email: email,
                        compliance_officer: compliance_officer,
                        bank_account_details: bank_account_details,
                        separate_account: separate_account,
                        separate_account_bank_name: separate_account_bank_name,
                        active: active
                    }
                };

                return $http(req);
            },

            createFSPProfileChange: function (fsp_id,
                                              profile_change_type,
                                              profile_change_status,
                                              followed_up_date_yn,
                                              followed_up_date,
                                              implemented_yn,
                                              implemented_on,
                                              submitted_yn,
                                              submission_date,
                                              description,
                                              notes,
                                              email_send,
                                              email_subject,
                                              email_body,
                                              email_attachments,
                                              verify_email_send,
                                              followed_up_with_fsp_yn,
                                              followed_up_with_fsp_on,
                                              followed_up_with_fsca_yn,
                                              followed_up_with_fsca_on,
                                              firebaseTokenId, visitorId) {
                var req = {
                    method: 'POST',
                    url: '/api/fsp/status_change/',
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 20000,
                    data: {
                        fsp_id: fsp_id,
                        type: profile_change_type,
                        status: profile_change_status,
                        follow_up_yn: followed_up_date_yn,
                        followed_up_date: followed_up_date,
                        implemented_yn: implemented_yn,
                        implemented_on: implemented_on,
                        submitted_yn: submitted_yn,
                        submission_date: submission_date,
                        description: description,
                        notes: notes.substring(0, 200),
                        email_send: email_send,
                        email_subject: email_subject,
                        email_body: email_body,
                        email_attachments: email_attachments,
                        verify_email_send: verify_email_send,
                        followed_up_with_fsp_yn: followed_up_with_fsp_yn,
                        followed_up_with_fsp_on: followed_up_with_fsp_on,
                        followed_up_with_fsca_yn: followed_up_with_fsca_yn,
                        followed_up_with_fsca_on: followed_up_with_fsca_on
                    }
                };

                return $http(req);
            },


            editFSPProfileChange: function (fsp_id, fsp_status_change_id,
                                            profile_change_status,
                                            followed_up_date_yn,
                                            followed_up_date,
                                            implemented_yn,
                                            implemented_on,
                                            submitted_yn,
                                            submission_date,
                                            description,
                                            notes,
                                            email_attachments,
                                            email_subject,
                                            email_body,
                                            send_email,
                                            followed_up_with_fsp_yn,
                                            followed_up_with_fsp_on,
                                            followed_up_with_fsca_yn,
                                            followed_up_with_fsca_on,
                                            firebaseTokenId, visitorId) {
                var req = {
                    method: 'PUT',
                    url: `/api/fsp/status_change/${fsp_status_change_id}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 20000,
                    data: {
                        fsp_id: fsp_id,
                        status: profile_change_status,
                        follow_up_yn: followed_up_date_yn,
                        followed_up_date: followed_up_date,
                        implemented_yn: implemented_yn,
                        implemented_on: implemented_on,
                        submitted_yn: submitted_yn,
                        submission_date: submission_date,
                        description: description,
                        notes: notes.substring(0, 200),
                        email_attachments: email_attachments,
                        email_subject: email_subject,
                        email_body: email_body,
                        send_email: send_email,
                        is_follow_up: false,
                        followed_up_with_fsp_yn: followed_up_with_fsp_yn,
                        followed_up_with_fsp_on: followed_up_with_fsp_on,
                        followed_up_with_fsca_yn: followed_up_with_fsca_yn,
                        followed_up_with_fsca_on: followed_up_with_fsca_on
                    }
                };

                return $http(req);
            },


            followUpFSPProfileChange: function (fsp_id, fsp_status_change_id,
                                                profile_change_status,
                                                followed_up_date_yn,
                                                followed_up_date,
                                                description,
                                                email_attachments,
                                                email_subject,
                                                email_body,
                                                original_email_body,
                                                firebaseTokenId, visitorId) {
                var req = {
                    method: 'PUT',
                    url: `/api/fsp/status_change/${fsp_status_change_id}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 20000,
                    data: {
                        fsp_id: fsp_id,
                        status: profile_change_status,
                        follow_up_yn: followed_up_date_yn,
                        followed_up_date: followed_up_date,
                        description: description,
                        email_attachments: email_attachments,
                        email_subject: email_subject,
                        email_body: email_body,
                        original_email_body: original_email_body,
                        is_follow_up: true
                    }
                };

                return $http(req);
            },


            editFSP: function (id, name, logo, website, fsp_number, cipc_registration_number, financial_year_end,
                               date_authorized, ai_number, physical_address,
                               postal_address, contact_person,
                               tel, email, separate_account, separate_account_bank_name, compliance_officer, bank_account_details, active,
                               firebaseTokenId, visitorId) {
                var req = {
                    method: 'PUT',
                    url: `/api/fsp/${id}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 20000,
                    data: {
                        name: name,
                        logo: logo,
                        website: website,
                        fsp_number: fsp_number,
                        cipc_registration_number: cipc_registration_number,
                        financial_year_end: financial_year_end,
                        date_authorized: date_authorized,
                        ai_number: ai_number,
                        physical_address: physical_address,
                        postal_address: postal_address,
                        contact_person: contact_person,
                        tel: tel,
                        email: email,
                        compliance_officer: compliance_officer,
                        bank_account_details: bank_account_details,
                        separate_account: separate_account,
                        separate_account_bank_name: separate_account_bank_name,
                        active: active
                    }
                };

                return $http(req);
            },

            deleteFSP: function (id, firebaseTokenId, visitorId) {
                var req = {
                    method: 'DELETE',
                    url: `/api/fsp/${id}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);
            },

            deleteFSPProfileChange: function (id, firebaseTokenId, visitorId) {
                var req = {
                    method: 'DELETE',
                    url: `/api/fsp/status_change/${id}`,
                    headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                    timeout: 8000
                };

                return $http(req);
            },


        }

    });
