angular.module('ics')
    .controller('AdminDashboardController', function ($scope, $rootScope, $state, $controller, FirebaseAuthService, AdminService) {

    angular.extend(this, $controller('BaseAdminController', {$scope: $scope}));

    getSuperAdminList();
    function getSuperAdminList() {
        FirebaseAuthService
          .getUserToken()
          .then(tokenId => AdminService.getSuperAdminList(tokenId, $scope.visitorId))
          .then((response) => {
              $scope.adminUsers = response.data.admin_users;
              $scope.$applyAsync();
          })
          .catch(err => $scope.checkErrStatus(err));
    }
});