angular.module('ics')
    .factory('AdminService', function ($http) {

    var service = {

        signupAdminUser: function (first_name, last_name, role, firebaseTokenId, visitorId) {
            const req = {
                method: 'POST',
                url: '/api/admin/new_user',
                headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                timeout: 8000,
                data: {
                    'first_name': first_name,
                    'last_name': last_name,
                    'role': role
                }
            };

            return $http(req);
        },

        getSuperAdminList: function (firebaseTokenId, visitorId) {
            var req = {
                method: 'GET',
                url: `/api/admin/super_admin`,
                headers: {
                    'x-firebase-token': firebaseTokenId,
                    'Content-Type': 'application/json',
                    'VisitorId': visitorId || getCookie("ics-visitor-id"),
                    'TwoFAAuthorizationToken': getCookie("ics-2fa-auth-token")
                },
                timeout: 8000
            };
            return $http(req);
        },
    };

    return service;

});